import React, { Ref } from 'react';

// helpers
import { OrganizationAddressTypes } from '../../../../../enums/onboarding/crm';
import { FormikHelpers, FormikProps } from 'formik';
import { IdentificationStepForOrganizationValidationSchema } from '../../../../../validations/onboarding-new/identification';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

export interface AddressItemModel {
  _id?: string;
  isPrimary: boolean;
  type: OrganizationAddressTypes[] | null;
  country: string | null;
  city: string;
  street: string;
  state: string;
  postalCode: string;
}

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  isRegulated: boolean | null;
  regulationCountry: string[];
  canIssueBearerShares: boolean | null;
  addresses: AddressItemModel[];
  disabledRegulated?: boolean;
}

const IdentificationForOrganizationForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      disabled={disabled}
      validationSchema={IdentificationStepForOrganizationValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <HideIfDisabledForm>
            <SubmitButtons showOnlySaveButton={showOnlySaveButton} />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default IdentificationForOrganizationForm;
