import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { message } from 'antd';
import { StateModel } from 'redux/reducers';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { setChangeRequest } from 'redux/actions/personalDetails';
import { OnboardingHelpers } from 'helpers/crm/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { personalDetailsAPIAdapter } from 'apiAdapters/profile/personalDetailsAPIAdapter';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';
import {
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import IdentificationForIndividualForm, {
  AddressItemModel,
  FormValuesModel,
  PassportItemModel,
} from 'components/Forms/TemplateForms/Onboarding/IdentificationForIndividualForm';

const Identification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const { changeRequest, contact } = useSelector<
    StateModel,
    PersonalDetailsStateModel
  >((state) => state.personalDetails);

  const { response, loading } = useFetch(() => {
    return changeRequest || contact
      ? onboardingAPI.fetchIdentificationStepData(
          changeRequest ? changeRequest._id : (contact?._id as string),
        )
      : null;
  }, [contact, changeRequest]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (
      contact &&
      ((!changeRequest && response) ||
        (!changeRequest?.changes?.contactKYCChange?.identification &&
          response) ||
        changeRequest?.changes?.contactKYCChange?.identification)
    ) {
      const contactData =
        changeRequest?.changes?.contactKYCChange?.identification || contact;

      let addresses: AddressItemModel[] = [
        {
          isPrimary: true,
          type: null,
          country: null,
          city: '',
          street: '',
          state: '',
          postalCode: '',
        },
      ];

      let passports: PassportItemModel[] = [
        {
          number: '',
          country: '',
          issuedAt: null,
          expirationDate: null,
          document: [],
        },
      ];

      if (response?.addresses.data.length) {
        addresses = response.addresses.data.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          postalCode: address.postalCode,
          review: address.review,
          documents:
            address?.documents &&
            address.documents.map((doc) => ({
              id: doc.id,
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              file: null,
            })),
        }));
      }

      if (response?.passportExpiringDocuments.data.length) {
        passports = response.passportExpiringDocuments.data.map(
          OnboardingHelpers.formatExpiringDocumentToPassportFormItemModel,
        );
      }

      return {
        submitActionType: null,
        isSave: false,
        isPEP:
          typeof contactData.isPEP === 'boolean' ? contactData.isPEP : null,
        pepInformation: contactData.pepInformation || '',
        isRegulated:
          typeof contactData.isRegulated === 'boolean'
            ? contactData.isRegulated
            : null,
        regulationCountry: contactData.regulationCountry,
        addresses,
        passports,
        nationality: contactData.nationality || [],
      };
    } else {
      return null;
    }
  }, [contact, changeRequest, response]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (changeRequest) {
      const response = await personalDetailsAPI.submitKYCIdentificationTab(
        changeRequest._id,
        personalDetailsAPIAdapter.formatKYCIdentificationFormValuesAPIPayload(
          values,
        ),
      );

      message.success(t('success_save'));
      dispatch(setChangeRequest(response));
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues ? (
        <IdentificationForIndividualForm
          showOnlySaveButton
          onSubmit={handleSubmit}
          disabled={
            !changeRequest ||
            changeRequest.status !== ChangeRequestStatuses.InProgress
          }
          initialFormValues={initialFormValues}
        />
      ) : null}
    </LoadingWrapper>
  );
};

export default Identification;
