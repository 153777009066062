import React, { Ref } from 'react';

// helpers
import { Moment } from 'moment';
import { ContactAddressTypes } from '../../../../../enums/onboarding/crm';
import { DocumentFieldValueModel } from 'components/Forms/FormComponents/DocumentsField';
import { FormikHelpers, FormikProps } from 'formik';
import { IdentificationStepForIndividualValidationSchema } from '../../../../../validations/onboarding-new/identification';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

type SubmitType = (
  values: FormValuesModel,
  helpers: FormikHelpers<FormValuesModel>,
) => Promise<void>;

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel;
  onSubmit: SubmitType | null;
  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

export type AddressItemModel = {
  _id?: string;
  isPrimary: boolean;
  type: ContactAddressTypes[] | null;
  country: string | null;
  city: string;
  street: string;
  state: string;
  postalCode: string;
  documents?: DocumentFieldValueModel[];
  review?: {
    isValid: boolean;
    comment: string;
  };
};

export type PassportItemModel = {
  _id?: string;
  number: string;
  country: string;
  issuedAt: Moment | null;
  expirationDate: Moment | null;
  document: DocumentFieldValueModel[];
  review?: {
    isValid: boolean;
    comment: string;
  };
};

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  isPEP: boolean | null;
  pepInformation: string;
  disabledPep?: boolean;
  isRegulated: boolean | null;
  regulationCountry: string[];
  addresses: AddressItemModel[];
  passports: PassportItemModel[];
  nationality: string[];
}

const IdentificationForIndividualForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      confirmExitWithoutSaving
      onSubmit={onSubmit ? onSubmit : console.log}
      initialValues={initialFormValues}
      disabled={disabled}
      validationSchema={IdentificationStepForIndividualValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <HideIfDisabledForm>
            <SubmitButtons showOnlySaveButton={showOnlySaveButton} />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default IdentificationForIndividualForm;
