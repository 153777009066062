import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FieldValidationRule } from '@core_components/FormField';

// components
import Input, { InputProps } from '@core_components/Input';

interface FormInputProps extends InputProps {
  validationRules?: FieldValidationRule[];
}

const FormInput = memo(
  ({
    validationRules,
    placeholder: propsPlaceholder,
    ...rest
  }: FormInputProps) => {
    const { t } = useTranslation('form');

    const placeholder = useMemo(() => {
      if (propsPlaceholder) {
        return propsPlaceholder;
      }

      if (!validationRules || !validationRules.length) {
        return t('field_placeholders.input_optional');
      }

      const validationTests = validationRules.reduce<{
        isRequired: boolean;
        min: null | number;
        max: null | number;
      }>(
        (acc, next) => {
          if (next.type === 'required') {
            acc.isRequired = true;
          }

          if (next.type === 'min') {
            acc.min = next.params?.min
              ? next.params?.min == 1
                ? 0
                : next.params?.min
              : 0;
          }

          if (next.type === 'max') {
            acc.max = next.params?.max || 0;
          }

          return acc;
        },
        {
          isRequired: false,
          min: null,
          max: null,
        },
      );

      let result = validationTests.isRequired
        ? t('field_placeholders.input_required')
        : t('field_placeholders.input_optional');

      if (validationTests.min || validationTests.max) {
        if (validationTests.min && !validationTests.max) {
          result = `${result} (${t('field_placeholders.min_length', { number: validationTests.min })})`;
        } else if (validationTests.max && !validationTests.min) {
          result = `${result} (${t('field_placeholders.max_length', { number: validationTests.max })})`;
        } else {
          result = `${result} (${t('field_placeholders.min_max_length', {
            minNumber: validationTests.min,
            maxNumber: validationTests.max,
          })})`;
        }
      }
      return result;
    }, [validationRules, propsPlaceholder]);

    return <Input {...rest} placeholder={placeholder} />;
  },
);

export default FormInput;
