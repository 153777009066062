// constants
import { IEntity } from '../../typings/application/entity';
import { DateHelpers } from 'helpers/date';
import { ContactModel } from '../../typings/application/contact';
import { EntityHelpers } from '../../helpers/crm/entity';
import { ContactHelpers } from '../../helpers/crm/contact';
import { OnboardingHelpers } from '../../helpers/crm/onboarding';
import { formatBooleanValue } from 'helpers/utils';
import { IOnboardingAccount } from '../../typings/onboarding/account';
import { RelationshipHelpers } from '../../helpers/crm/relationship';
import { RelationshipTemplate } from '../../typings/application/relationship-template';
import { AdditionalFieldHelpers } from '../../helpers/additionalField';
import { NumberOfOfficesOptions } from '../../components/Forms/FormComponents/SelectInputs/CRM/NumberOfOfficesSelect';
import { NumberOfEmployeesOptions } from '../../components/Forms/FormComponents/SelectInputs/CRM/NumberOfEmployeesSelect';
import { RelationshipComparationTypes } from '../../typings/crm/relationships';
import {
  Node,
  Edge,
  RelationshipsGraph,
} from '../../typings/relationshipsGraph';
import {
  WireRangeKeys,
  WireRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/WireRangesSelect';
import {
  EdgeStatuses,
  GraphData,
  GraphNode,
  NodeReviewStatuses,
  NodeStatuses,
} from '../../components/Charts/GraphChart';
import {
  InitialDepositRangeKeys,
  InitialDepositRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';
import {
  EstimatedAmountFundsRangeKeys,
  EstimatedAmountFundsRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';
import {
  FetchOnboardingAnswersResponseModel,
  StartOnboardingBodyModel,
  UpdateContactShortInfoRequestBodyModel,
  UpdateOrganizationShortInfoRequestBodyModel,
} from '../../api/onboarding/onboardingAPI';
import {
  AccountTypes,
  AdditionalFieldTypes,
  AssociationEntries,
  EmailTypes,
  EntityTypes,
  OnboardingEntryTypes,
  OnboardingStatuses,
  PhoneNumberTypes,
  SocialMediaTypes,
} from '../../enums/onboarding/crm';

// form values models
import { FormValuesModel as SignUpFormValuesModel } from '../../components/Forms/TemplateForms/Auth/SignUpForm';
import { FormValuesModel as IdentificationStepFormValues } from '../../components/Forms/TemplateForms/Onboarding/IdentificationForOrganizationForm';
import { FormValuesModel as OnboardingNodeFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/EditNodeModalDialog';
import { FormValuesModel as SourceOfWealthStepFormValuesModel } from '../../components/Forms/TemplateForms/Onboarding/SourceOfWealthForm';
import { FormValuesModel as ApplicantInformationStepFormValues } from '../../components/Forms/TemplateForms/Onboarding/GeneralInformationForm';
import { FormValuesModel as ApplicantInformationShortStepFormValues } from '../../components/Forms/TemplateForms/Onboarding/ApplicantInformationShortForm';
import { FormValuesModel as IdentificationForIndividualStepFormValues } from '../../components/Forms/TemplateForms/Onboarding/IdentificationForIndividualForm';
import { FormValuesModel as ApplicationDocumentationStepFormValuesModel } from '../../components/Forms/TemplateForms/Onboarding/ApplicationDocumentationForm';
import { FormValuesModel as ApplicantInformationIndividualStepFormValues } from '../../components/Forms/TemplateForms/Onboarding/ApplicantInformationForm';
import {
  AccountFormItemModel,
  FormValuesModel as AccountInformationStepFormValues,
} from '../../components/Forms/TemplateForms/Onboarding/AccountInformationForm';

import {
  AccountInformationStepBodyModel,
  AnswerOperation,
  ApplicantInformationIndividualRequestBodyModel,
  ApplicantInformationIndividualWithNoKeyRelationshipRequestBodyModel,
  ApplicantInformationOrganizationRequestBodyModel,
  ApplicationDocumentationBodyModel,
  CreateAnswerOperation,
  IdentificationForIndividualRequestBodyModel,
  IdentificationRequestBodyModel,
  IKeyRelationshipInstance,
  OnboardingStatusModel,
  OrganizationNameModel,
  RangeOptionModel,
  RemoveAnswerOperation,
  RequestBodyAddressItemModel,
  SocialMediaModel,
  SourceOfWealthRequestBodyModel,
  UpdateAnswerOperation,
} from '../../typings/onboarding/onboarding';

const getFormattedNames = (
  legalName: string,
  tradeName?: string,
): OrganizationNameModel[] => {
  const result = [{ type: EntityTypes.Legal, name: legalName.trim() }];

  if (tradeName) {
    result.push({ type: EntityTypes.Trader, name: tradeName });
  }

  return result;
};

const getSocialMedia = (socialMedia: {
  websiteLink?: string;
  twitterLink?: string;
  facebookLink?: string;
  linkedInLink?: string;
}): SocialMediaModel[] => {
  const result: SocialMediaModel[] = [];

  if (socialMedia.twitterLink) {
    result.push({
      type: SocialMediaTypes.Twitter,
      path: socialMedia.twitterLink,
    });
  }

  if (socialMedia.facebookLink) {
    result.push({
      type: SocialMediaTypes.Facebook,
      path: socialMedia.facebookLink,
    });
  }

  if (socialMedia.linkedInLink) {
    result.push({
      type: SocialMediaTypes.LinkedIn,
      path: socialMedia.linkedInLink,
    });
  }

  if (socialMedia.websiteLink) {
    result.push({
      type: SocialMediaTypes.WebSite,
      path: socialMedia.websiteLink,
    });
  }

  return result;
};

const getNodeReviewStatus = (
  status?: OnboardingStatuses,
): NodeReviewStatuses | undefined => {
  switch (status) {
    case OnboardingStatuses.AdditionalInfoRequired:
    case OnboardingStatuses.Rejected:
      return NodeReviewStatuses.Rejected;

    case OnboardingStatuses.Submitted:
      return NodeReviewStatuses.Submitted;

    case OnboardingStatuses.NotSubmitted:
      return NodeReviewStatuses.NotSubmitted;

    case OnboardingStatuses.InAccountManagementReview:
    case OnboardingStatuses.InReview:
      return NodeReviewStatuses.InReview;

    default:
      return undefined;
  }
};

const getNodeStatus = (
  node: Node,
  edges: Edge[],
  applicationIsUnderReview?: boolean,
): NodeStatuses | undefined => {
  const relationshipsStatus = edges.reduce(
    (acc, next) => {
      if (next.parent.id === node.id) {
        if (!next.metadata.comparationResult) {
          acc.old += 1;
        } else if (
          next.metadata.comparationResult === RelationshipComparationTypes.Added
        ) {
          acc.new += 1;
        } else if (
          next.metadata.comparationResult ===
          RelationshipComparationTypes.Removed
        ) {
          acc.removed += 1;
        }
      }

      return acc;
    },
    { new: 0, old: 0, removed: 0 },
  );

  // Could be 4 cases:
  // 1. new (green color) - all relationships have added status
  // 2. updated (yellow color) - at least one with status old or removed (but not all removed)
  // 3. removed (red color) - all existing relationships have removed status
  // 4. no changes (gray) - all relationships have old status

  // Pending review and something was modified
  if (
    applicationIsUnderReview &&
    (relationshipsStatus.new || relationshipsStatus.removed)
  ) {
    return NodeStatuses.Updated;
  }

  // Case 1 - new tree item
  if (
    !relationshipsStatus.old &&
    !relationshipsStatus.removed &&
    relationshipsStatus.new
  ) {
    return NodeStatuses.New;
  }
  // Case 3 - fully removed tree item
  else if (
    !relationshipsStatus.old &&
    !relationshipsStatus.new &&
    relationshipsStatus.removed
  ) {
    return NodeStatuses.Deleted;
  }

  // Case 4 - no changes
  else if (
    !relationshipsStatus.new &&
    !relationshipsStatus.removed &&
    relationshipsStatus.old
  ) {
    return NodeStatuses.NoChanges;
  }

  // Case 2 - updated
  else {
    return NodeStatuses.NoChanges;
  }
};

const getEdgeStatus = (
  relationshipComparationStatus: RelationshipComparationTypes,
  applicationIsUnderReview?: boolean,
): EdgeStatuses => {
  if (applicationIsUnderReview) {
    return EdgeStatuses.Updated;
  }

  switch (relationshipComparationStatus) {
    case RelationshipComparationTypes.Added:
      return EdgeStatuses.New;

    case RelationshipComparationTypes.Removed:
      return EdgeStatuses.Deleted;
  }
};

const onboardingAPIAdapter = {
  startOnboardingProcess: async (
    values: SignUpFormValuesModel,
    invitationCode: string,
  ): Promise<StartOnboardingBodyModel> => {
    return {
      type: values.accountType as OnboardingEntryTypes,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phoneNumber: values.phoneNumber,
      email: values.email.trim(),
      password: values.password,
      keyRelationshipTemplateId: values.keyRelationshipTemplateId as string,
      behaveOfSombodyElse: false,
      code: invitationCode,
    };
  },

  fetchAccountInformationStepData: (
    accounts: IOnboardingAccount[],
  ): AccountFormItemModel[] => {
    if (!accounts.length) {
      return [];
    }

    return accounts.map((account) => ({
      id: account._id,
      type: account.type as AccountTypes,
      currencyId: account.currencyId || null,
      purpose: account.purpose || '',

      initialDeposit: account.initialDeposit
        ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
            account.initialDeposit as RangeOptionModel,
            InitialDepositRangeOptions,
          ) as InitialDepositRangeKeys)
        : null,

      initialDepositOrigin: account.initialDepositOrigin || null,

      jurisdictions: account.jurisdictions || [],

      incomingWiresNumber: account.incomingWiresNumber
        ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
            account.incomingWiresNumber as RangeOptionModel,
            WireRangeOptions,
          ) as WireRangeKeys)
        : null,

      outgoingWiresNumber: account.outgoingWiresNumber
        ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
            account.outgoingWiresNumber as RangeOptionModel,
            WireRangeOptions,
          ) as WireRangeKeys)
        : null,

      estimatedIncomingFunds: account.estimatedIncomingFunds
        ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
            account.estimatedIncomingFunds as RangeOptionModel,
            EstimatedAmountFundsRangeOptions,
          ) as EstimatedAmountFundsRangeKeys)
        : null,

      estimatedOutgoingFunds: account.estimatedOutgoingFunds
        ? (OnboardingHelpers.getKeyOfRangeByRangeOption(
            account.estimatedOutgoingFunds as RangeOptionModel,
            EstimatedAmountFundsRangeOptions,
          ) as EstimatedAmountFundsRangeKeys)
        : null,
    }));
  },

  submitApplicantInformationOrganizationStep: (
    formValues: ApplicantInformationStepFormValues,
  ): ApplicantInformationOrganizationRequestBodyModel => {
    const numberOfEmployees = formValues.numberOfEmployees
      ? NumberOfEmployeesOptions[formValues.numberOfEmployees]
      : null;
    const numberOfOffices = formValues.numberOfOffices
      ? NumberOfOfficesOptions[formValues.numberOfOffices]
      : null;

    return {
      isSubmit: !formValues.isSave,
      names: getFormattedNames(formValues.legalName, formValues.tradeName),
      dateOfIncorporation: formValues.dateOfIncorporation
        ? DateHelpers.formatToYYYYMMDD(formValues.dateOfIncorporation)
        : '',
      industryCodes: formValues.industryCodes,
      natureOfBusiness: formValues.natureOfBusiness.trim(),
      numberOfEmployees,
      numberOfOffices,
      registrationNumber: formValues.registrationNumber.trim(),
      jurisdiction: formValues.jurisdiction,
      socialMedia: getSocialMedia(formValues.socialMedia),
    };
  },

  submitApplicantInformationIndividualStep: (
    formValues: ApplicantInformationIndividualStepFormValues,
  ): ApplicantInformationIndividualRequestBodyModel => {
    const result = {
      isSubmit: !formValues.isSave,
      firstName: formValues.firstName.trim(),
      middleName: formValues.middleName.trim(),
      lastName: formValues.lastName.trim(),
      dateOfBirth: formValues.dateOfBirth
        ? DateHelpers.formatToYYYYMMDD(formValues.dateOfBirth)
        : '',
      countryOfBirth: formValues.countryOfBirth,
      gender: formValues.gender,
      emails: formValues.emails.map((e) => ({
        type: e.type as EmailTypes,
        address: e.value,
        isPrimary: e.primary.status,
      })),
      phoneNumbers: formValues.phoneNumbers.map((e) => ({
        type: e.type as PhoneNumberTypes,
        number: e.value,
        isPrimary: e.primary.status,
      })),
      socialMedia: getSocialMedia(formValues.socialMedia),
    };

    return result;
  },

  submitApplicantInformationIndividualWithNoKeyRelationshipStep: (
    formValues: ApplicantInformationShortStepFormValues,
  ): ApplicantInformationIndividualWithNoKeyRelationshipRequestBodyModel => {
    const result = {
      isSubmit: !formValues.isSave,
      firstName: formValues.firstName.trim(),
      middleName: formValues.middleName.trim(),
      lastName: formValues.lastName.trim(),
      emails: formValues.emails.map((e) => ({
        type: e.type as EmailTypes,
        address: e.value,
        isPrimary: e.primary.status,
      })),
      phoneNumbers: formValues.phoneNumbers.map((e) => ({
        type: e.type as PhoneNumberTypes,
        number: e.value,
        isPrimary: e.primary.status,
      })),
    };

    return result;
  },

  submitIdentificationStep: (
    values: IdentificationStepFormValues,
  ): IdentificationRequestBodyModel => ({
    type: OnboardingEntryTypes.Organization,
    data: {
      isSubmit: !values.isSave,
      canIssueBearerShares: formatBooleanValue(values.canIssueBearerShares),
      isRegulated: formatBooleanValue(values.isRegulated),
      regulationCountry: values.isRegulated ? values.regulationCountry : [],
      addresses: values.addresses as RequestBodyAddressItemModel[],
    },
  }),

  submitIdentificationForIndividualStep: (
    values: IdentificationForIndividualStepFormValues,
  ): IdentificationForIndividualRequestBodyModel => ({
    type: OnboardingEntryTypes.Contact,
    data: {
      isSubmit: !values.isSave,
      isPEP: formatBooleanValue(values.isPEP),
      pepInformation: values.pepInformation.trim(),
      nationality: values.nationality,
      isRegulated: formatBooleanValue(values.isRegulated),
      regulationCountry: values.isRegulated ? values.regulationCountry : [],
      addresses: values.addresses.map((a) => ({
        _id: a._id,
        isPrimary: a.isPrimary,
        type: a.type,
        country: a.country,
        city: a.city,
        street: a.street,
        state: a.state,
        postalCode: a.postalCode,
        documentIds: a.documents?.map((d) => d.id),
      })) as RequestBodyAddressItemModel[],
      passports: values.passports.map(
        ({ issuedAt, expirationDate, document, ...rest }) => ({
          ...rest,
          issuedAt: issuedAt ? DateHelpers.formatToYYYYMMDD(issuedAt) : '',
          expirationDate: expirationDate
            ? DateHelpers.formatToYYYYMMDD(expirationDate)
            : '',
          documentIds: document.map((e) => e.id),
        }),
      ),
    },
  }),

  submitAccountInformationStep: (
    values: AccountInformationStepFormValues,
  ): AccountInformationStepBodyModel => ({
    isSubmit: !values.isSave,
    accounts: values.accounts.map((account) => ({
      _id: account.id,
      type: account.type as AccountTypes,
      currencyId: account.currencyId,
      purpose: account.purpose.trim(),
      initialDeposit: account.initialDeposit
        ? InitialDepositRangeOptions[account.initialDeposit]
        : null,
      initialDepositOrigin: account.initialDepositOrigin,
      jurisdictions: account.jurisdictions,
      incomingWiresNumber: account.incomingWiresNumber
        ? WireRangeOptions[account.incomingWiresNumber]
        : null,
      outgoingWiresNumber: account.outgoingWiresNumber
        ? WireRangeOptions[account.outgoingWiresNumber]
        : null,
      estimatedIncomingFunds: account.estimatedIncomingFunds
        ? EstimatedAmountFundsRangeOptions[account.estimatedIncomingFunds]
        : null,
      estimatedOutgoingFunds: account.estimatedOutgoingFunds
        ? EstimatedAmountFundsRangeOptions[account.estimatedOutgoingFunds]
        : null,
    })),
  }),

  submitSourceOfWealthStep: (
    values: SourceOfWealthStepFormValuesModel,
  ): SourceOfWealthRequestBodyModel => {
    const {
      isSave,
      employmentHistory,
      sourceOfWealthCategories,
      sourceOfWealthDescription,
    } = values;
    const manualEmploymentHistory = employmentHistory.find(
      (e) => !e.isInheritedFromRelationships,
    );

    return {
      isSubmit: !isSave,
      sourceOfWealthCategories,
      sourceOfWealthDescription: sourceOfWealthDescription.trim(),
      employmentHistory:
        manualEmploymentHistory &&
        typeof manualEmploymentHistory.isSelfEmployed === 'boolean'
          ? {
              employerName:
                (!manualEmploymentHistory.isBusinessUnderOwnName &&
                  manualEmploymentHistory.isSelfEmployed) ||
                !manualEmploymentHistory.isSelfEmployed
                  ? manualEmploymentHistory.employerName.trim()
                  : '',
              occupation:
                (!manualEmploymentHistory.isBusinessUnderOwnName &&
                  manualEmploymentHistory.isSelfEmployed) ||
                !manualEmploymentHistory.isSelfEmployed
                  ? manualEmploymentHistory.occupation.trim()
                  : '',
              natureOfBusiness: manualEmploymentHistory.isSelfEmployed
                ? manualEmploymentHistory.natureOfBusiness.trim()
                : '',
              isSelfEmployed: formatBooleanValue(
                manualEmploymentHistory.isSelfEmployed,
              ),
              isBussinessUnderOwnName: !manualEmploymentHistory.isSelfEmployed
                ? null
                : formatBooleanValue(
                    manualEmploymentHistory.isBusinessUnderOwnName,
                  ),
            }
          : null,
    };
  },

  formatApplicationDocumentationAnswersToQueryOperations: (
    values: ApplicationDocumentationStepFormValuesModel,
    initialFormValues: ApplicationDocumentationStepFormValuesModel,
  ): AnswerOperation[] => {
    const { additionalFields, additionalDocuments } = values;

    const filteredAdditionalFields = additionalFields.filter(
      (e) => !e.review?.isValid,
    );

    const operations: AnswerOperation[] = filteredAdditionalFields.map((e) => {
      const reason = e.isNotProvidedReason
        ? e.notProvidedReason.trim()
        : undefined;
      const valueJSON = !e.isNotProvidedReason
        ? AdditionalFieldHelpers.formatToJSON(e.type, e.value)
        : undefined;
      const isProvidedAnswer = !!valueJSON || !!reason;

      return {
        type: 'answer',
        payload: {
          id: e.id as string,
          answer: isProvidedAnswer
            ? {
                isProvided: !e.isNotProvidedReason,
                reason,
                valueJSON,
              }
            : null,
        },
      };
    });

    if (
      additionalDocuments.length ||
      initialFormValues.additionalDocuments.length
    ) {
      const initialAdditionalDocumentsIds =
        initialFormValues.additionalDocuments.map((e) => e.id) as string[];

      additionalDocuments.forEach((e) => {
        const valueJSON = AdditionalFieldHelpers.formatToJSON(
          AdditionalFieldTypes.Document,
          e.documents,
        );

        if (e.id) {
          const updateOperation: UpdateAnswerOperation = {
            type: 'update',
            payload: {
              id: e.id,
              answer: {
                isProvided: true,
                valueJSON,
              },
              field: {
                type: AdditionalFieldTypes.Document,
                name: e.name,
                description: e.description,
              },
            },
          };

          operations.push(updateOperation);
          initialAdditionalDocumentsIds.splice(
            initialAdditionalDocumentsIds.indexOf(e.id),
            1,
          );
        } else {
          const createOperation: CreateAnswerOperation = {
            type: 'create',
            payload: {
              answer: {
                isProvided: true,
                valueJSON,
              },
              field: {
                type: AdditionalFieldTypes.Document,
                name: e.name,
                description: e.description,
              },
            },
          };

          operations.push(createOperation);
        }
      });

      if (initialAdditionalDocumentsIds.length) {
        initialAdditionalDocumentsIds.forEach((id) => {
          const deleteOperation: RemoveAnswerOperation = {
            type: 'remove',
            payload: { id },
          };

          operations.push(deleteOperation);
        });
      }
    }

    return operations;
  },

  submitApplicationDocumentationStep: (
    values: ApplicationDocumentationStepFormValuesModel,
    initialFormValues: ApplicationDocumentationStepFormValuesModel,
    clientGroupId: string,
    itemId: string,
    itemType: OnboardingEntryTypes,
  ): ApplicationDocumentationBodyModel => {
    return {
      clientGroupId,
      itemId,
      itemType,
      operations:
        onboardingAPIAdapter.formatApplicationDocumentationAnswersToQueryOperations(
          values,
          initialFormValues,
        ),
    };
  },

  generateDataForApplicationDocumentationFromOnboardingAnswers: (
    answers: FetchOnboardingAnswersResponseModel,
    onboardingStatus: OnboardingStatusModel,
  ): ApplicationDocumentationStepFormValuesModel => {
    const { client, admin, relationship_keyRelationshipTemplate } = answers;

    const additionalDocuments = client.data.map((e) => {
      const { _id, field, answer } = e;
      return {
        id: _id,
        name: field.name,
        description: field.description,
        documents: AdditionalFieldHelpers.formatFromJSON(
          field.type,
          answer?.valueJSON || '',
        ),
      };
    });

    const additionalFields = [
      ...relationship_keyRelationshipTemplate.data,
      ...admin.data,
    ].map((e) => {
      const { field, answer, review } = e;

      const isNotProvidedReason = Boolean(answer && !answer.isProvided);
      const value =
        !isNotProvidedReason && answer
          ? AdditionalFieldHelpers.formatFromJSON(field.type, answer.valueJSON)
          : undefined;
      const notProvidedReason = answer?.reason || '';

      return {
        id: e._id,
        value,
        review,
        name: field.name,
        description: field.description,
        isNotProvidedReason,
        notProvidedReason,
        type: field.type,
        relatedTo: field.relatedTo,
        keyRelationshipTemplateId:
          onboardingStatus.item.keyRelationshipInstance?.templateId,
        options: {
          ...field.options,
          selectOptions: field.options.selectOptions.length
            ? field.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
            : [],
          formDocument: field.options.formDocument,
        },
      };
    });

    return {
      submitActionType: null,
      onboardingEntryType: onboardingStatus.type,
      additionalFields,
      additionalDocuments,
      documentsAssociation: {
        id: onboardingStatus.item._id,
        type:
          onboardingStatus.type === OnboardingEntryTypes.Contact
            ? AssociationEntries.Contact
            : AssociationEntries.Organization,
      },
    };
  },

  fetchStructureStepRelationships: (
    graphData: RelationshipsGraph,
    applicantOrganizationId: string,
    applicantContactId: string,
    isApplicationUnderReview?: boolean,
  ): GraphData => {
    const result: GraphData = {
      nodes: [],
      edges: [],
    };

    const { graph, relationshipTemplates, keyRelationshipTemplates } =
      graphData;
    const { nodes, edges } = graph;

    const hashedKeyRelTemplates = keyRelationshipTemplates.reduce<
      Record<string, IKeyRelationshipInstance>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    const hashedRelTemplates = relationshipTemplates.reduce<
      Record<string, RelationshipTemplate>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    const hashedNodes = nodes.reduce<Record<string, Node>>((acc, next) => {
      acc[next.id] = next;
      return acc;
    }, {});

    result.nodes = nodes.map<GraphNode>((e) => {
      const status = getNodeStatus(e, edges, isApplicationUnderReview);
      const reviewStatus = e.onboardingProcess
        ? getNodeReviewStatus(e.onboardingProcess.status)
        : undefined;
      const isApplicantContact =
        e.type === OnboardingEntryTypes.Contact && e.id === applicantContactId;
      const isApplicantOrganization =
        e.type === OnboardingEntryTypes.Organization &&
        e.id === applicantOrganizationId;
      const model =
        e.type === OnboardingEntryTypes.Contact
          ? { ...e.model, onlineAccess: e.onlineAccess }
          : e.model;

      const contact = e.model as ContactModel;
      const entity = e.model as IEntity;

      return {
        id: e.id,
        entryType: e.type,
        onboardingProcess: e.onboardingProcess,
        hasOwnershipRelationships: !!e.onboardingProcess?.isOwnership,
        label:
          e.type === OnboardingEntryTypes.Contact
            ? ContactHelpers.getFormattedContactName(
                contact.firstName,
                contact.lastName,
                contact.middleName,
              )
            : EntityHelpers.getEntityNameByNameType(entity.names),
        model: {
          ...model,
          keyRelationshipTemplate:
            hashedKeyRelTemplates[model.keyRelationshipTemplateId as string],
        },
        typeLabel: e.model.keyRelationshipTemplateId
          ? hashedKeyRelTemplates[e.model.keyRelationshipTemplateId]?.name
          : '',
        metadata: e.metadata,
        isApplicantOrganization,
        isApplicantContact,
        reviewStatus,
        status,
        sharedData:
          e.type === OnboardingEntryTypes.Contact
            ? !!e.onboardingProcess?.isShared
            : false,
      };
    });

    result.edges = edges.map((e) => {
      const status = e.metadata.comparationResult
        ? getEdgeStatus(e.metadata.comparationResult, isApplicationUnderReview)
        : undefined;
      const relatedKeyRelationshipId = hashedNodes[e.child.id].model
        .keyRelationshipTemplateId as string;
      const isOwnership =
        hashedRelTemplates[e.metadata.relationship.relationshipTemplateId] &&
        hashedKeyRelTemplates[relatedKeyRelationshipId]
          ? RelationshipHelpers.isRelationshipTemplateOwnership(
              hashedRelTemplates[
                e.metadata.relationship.relationshipTemplateId
              ],
              hashedKeyRelTemplates[relatedKeyRelationshipId],
            )
          : false;

      return {
        status,
        source: e.parent.id,
        target: e.child.id,
        label: RelationshipHelpers.getRelationshipLabel(
          e.metadata.relationship,
          hashedRelTemplates[e.metadata.relationship.relationshipTemplateId],
        ),
        isOwnership,
        relationship: e.metadata.relationship,
        relationshipTemplate:
          hashedRelTemplates[e.metadata.relationship.relationshipTemplateId],
      };
    });

    return result;
  },

  updateContactShortInfo: (
    values: OnboardingNodeFormValuesModel,
  ): UpdateContactShortInfoRequestBodyModel => {
    const { contact } = values;

    return {
      firstName: contact?.firstName?.trim() || '',
      middleName: contact?.middleName?.trim() || '',
      lastName: contact?.lastName?.trim() || '',
      isPEP: formatBooleanValue(contact?.isPEP),
      pepInformation: contact?.pepInformation as string,
    };
  },

  updateOrganizationShortInfo: (
    values: OnboardingNodeFormValuesModel,
  ): UpdateOrganizationShortInfoRequestBodyModel => {
    const { organization } = values;

    return {
      legalName: organization?.name?.trim() || '',
      isRegulated: formatBooleanValue(organization?.isRegulated),
      regulationCountry: organization?.isRegulated
        ? organization.regulatedCountries
        : [],
    };
  },
};

export { onboardingAPIAdapter, getSocialMedia };
