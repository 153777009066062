import React, { useContext, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from '../../../../../../../../../../api/onboarding/onboardingAPI';
import { VerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation';
import { onboardingAPIAdapter } from '../../../../../../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from '../../../../../../../../../../typings/onboarding/onboarding';

// components
import LoadingWrapper from '../../../../../../../../../WrapperComponents/LoadingWrapper';
import ApplicationDocumentationForm, {
  FormValuesModel,
} from '../../../../../../ApplicationDocumentationForm';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  onSave: (isCompleted: boolean) => void;
  isViewOnly?: boolean;
  showSubmitForReview?: boolean;
  canSubmitForReview?: boolean;
  canSendMessage?: boolean;
  onSubmitForReview?: () => void;
  onAction?: (actionType: ActionKeys | null) => void;
}
const ApplicationDocumentation = ({
  isViewOnly,
  onboardingStatus,
  showSubmitForReview,
  canSubmitForReview,
  canSendMessage,
  onSave,
  onSubmitForReview,
  onAction,
}: IProps) => {
  const { t } = useTranslation('common');
  const context = useContext(VerificationContext);
  const [updateAnswersTrigger, updateAnswers] = useState<any>();

  const { response: answersResponse, loading: answersLoader } = useFetch(
    () =>
      onboardingAPI.fetchApplicationDocumentationStepAnswers(
        onboardingStatus.clientGroupId,
        onboardingStatus.crmItemId,
      ),
    [onboardingStatus, updateAnswersTrigger],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!answersResponse) {
      return null;
    }

    return onboardingAPIAdapter.generateDataForApplicationDocumentationFromOnboardingAnswers(
      answersResponse,
      onboardingStatus,
    );
  }, [answersResponse, onboardingStatus]);

  const onSubmit = async (values: FormValuesModel) => {
    switch (values.submitActionType) {
      case 'save':
        {
          await submitApplicationDocumentationStep(values);
          message.success(t('success_save'));
          const { informationFilled } =
            await onboardingAPI.fetchStatusForOnboardingItem(
              onboardingStatus.crmItemId,
              onboardingStatus.applicationId,
            );
          updateAnswers({});
          onSave(informationFilled.applicationDocumentation);
        }
        break;

      case 'submit':
        {
          await submitApplicationDocumentationStep(values).then(
            async () =>
              await onboardingAPI.submitApplicationForReview(
                onboardingStatus.clientGroupId,
              ),
          );

          onSubmitForReview && onSubmitForReview();
        }
        break;

      case 'save-exit':
      case 'save-back':
        {
          await submitApplicationDocumentationStep(values);
          message.success(t('success_save'));

          onAction && onAction(values.submitActionType);
        }
        break;
    }
  };

  async function submitApplicationDocumentationStep(values: FormValuesModel) {
    const formattedBody =
      onboardingAPIAdapter.submitApplicationDocumentationStep(
        values,
        initialFormValues as FormValuesModel,
        onboardingStatus.clientGroupId,
        onboardingStatus.item._id,
        onboardingStatus.type,
      );

    await onboardingAPI.submitApplicationDocumentationStep(formattedBody);
  }

  return (
    <LoadingWrapper loading={answersLoader}>
      {initialFormValues && (
        <ApplicationDocumentationForm
          formRef={context?.refState}
          canSendMessage={canSendMessage}
          canSubmitForReview={canSubmitForReview}
          showOnlySaveButton={!showSubmitForReview}
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
        />
      )}
    </LoadingWrapper>
  );
};

export default ApplicationDocumentation;
