import React, { useCallback, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { RoutePaths } from 'routes/routes';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContactHelpers } from '../../../../helpers/crm/contact';
import { IClientGroupUser } from '../../../../typings/approvalManagement/accountManagement';
import { userManagementAPI } from '../../../../api/accountManagement/userManagementAPI';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import {
  ActionTypes,
  default as TemplateUsersTable,
} from '../../../../components/Tables/TableTemplates/AccountManagement/UsersTable';

// components
import EnableUserModal from '../EnableUserModal';
import UpdatePermissionsModal from '../../ClientProfile/UpdateClientPermissions/UpdatePermissionsModal';
import { message, Modal } from 'antd';

interface IProps {
  updateTableTrigger: any;
  updateTable: () => void;
}

const UsersTable = ({ updateTableTrigger, updateTable }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('account_management');
  const [currentPage, setPage] = useState(1);
  const [userToEnable, setEnableUser] = useState<IClientGroupUser | null>(null);
  const [userToUpdatePermissions, setUserToUpdatePermissions] =
    useState<IClientGroupUser | null>(null);
  const clientGroupId = useSelector<StateModel, string>(
    (state) => state.applications.activeApplication?.clientGroup?._id || '',
  );
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const { response, loading } = useFetch(
    () =>
      userManagementAPI.fetchUsers(clientGroupId, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, updateTableTrigger],
  );

  const handleActionsClick = useCallback(
    (key: ActionTypes, record: IClientGroupUser) => {
      switch (key) {
        case 'view_user':
          navigate({
            pathname: RoutePaths.AccountManagement_ClientProfile_ClientDetails,
            search: `?id=${record.contactId}&type=${OnboardingEntryTypes.Contact}`,
          });
          break;

        case 'update-permissions':
          setUserToUpdatePermissions(record);
          break;

        case 'enable_user':
          setEnableUser(record);
          break;

        case 'deactivate_user':
          {
            const username = ContactHelpers.getFormattedContactName(
              record.contact.firstName,
              record.contact.lastName,
              record.contact.middleName,
            );

            Modal.confirm({
              width: 600,
              icon: null,
              closable: true,
              maskClosable: true,
              content: t('user_management.deactivate_user.description'),
              okText: t('yes', { ns: 'common' }),
              cancelText: t('no', { ns: 'common' }),
              onOk: async () => {
                try {
                  await userManagementAPI.deactivateUser(
                    record.clientGroupId,
                    record._id,
                  );
                  message.success(
                    t('user_management.deactivate_user.success_message', {
                      username,
                    }),
                  );
                  updateTable();
                } catch (error: any) {
                  ErrorHandlerService.handleError(error);
                }
              },
            });
          }
          break;
      }
    },
    [],
  );

  const handleEnableUserClose = (isEnabled: boolean) => {
    setEnableUser(null);

    if (isEnabled) {
      updateTable();
    }
  };

  const handleUpdatePermissionsModalClose = (wasSubmitted?: boolean) => {
    setUserToUpdatePermissions(null);

    if (wasSubmitted) {
      updateTable();
    }
  };

  return (
    <>
      <TemplateUsersTable
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        current={currentPage}
        onPaginationChange={setPage}
        onActionsClick={handleActionsClick}
        disabledActions={!isTwoFactorVerificationEnabled}
      />

      <EnableUserModal
        user={userToEnable}
        visible={!!userToEnable}
        closeCallback={handleEnableUserClose}
      />
      <UpdatePermissionsModal
        isVisible={!!userToUpdatePermissions}
        closeCallback={handleUpdatePermissionsModalClose}
        clientGroupUser={userToUpdatePermissions}
      />
    </>
  );
};

export default UsersTable;
