import { DateHelpers } from 'helpers/date';
import { formatBooleanValue } from 'helpers/utils';
import { AdditionalFieldHelpers } from 'helpers/additionalField';
import { IDocumentAssociationInfo } from 'typings/documents/documents';
import { RequestBodyAddressItemModel } from 'typings/onboarding/onboarding';
import { FormValuesModel as SOWFormValues } from 'components/Forms/TemplateForms/Onboarding/SourceOfWealthForm';
import {
  getSocialMedia,
  onboardingAPIAdapter,
} from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { FormValuesModel as ContactGeneralFormValues } from 'components/Forms/TemplateForms/CRM/ContactGeneralForm';
import { FormValuesModel as IdentificationFormValues } from 'components/Forms/TemplateForms/Onboarding/IdentificationForIndividualForm';
import { FormValuesModel as ApplicantInformationFormValues } from 'components/Forms/TemplateForms/Onboarding/ApplicantInformationForm';
import {
  EmailTypes,
  OnboardingEntryTypes,
  PhoneNumberTypes,
} from 'enums/onboarding/crm';
import { FormValuesModel as ApplicationDocumentationStepFormValuesModel } from 'components/Forms/TemplateForms/Onboarding/ApplicationDocumentationForm';
import {
  SubmitKYCSOWTabData,
  SubmitKYCGeneralTabData,
  SubmitVerificationTabData,
  SubmitKYCIdentificationTabData,
  UpdatePersonalDetailsBodyModel,
  FetchVerificationDocumentsResponse,
} from 'api/profile/personalDetailsAPI';

const personalDetailsAPIAdapter = {
  updatePersonalDetails: (
    formValues: ContactGeneralFormValues,
  ): UpdatePersonalDetailsBodyModel => {
    return {
      firstName: formValues.firstName.trim(),
      middleName: formValues.middleName.trim(),
      lastName: formValues.lastName.trim(),
      emails: formValues.emails.map((e) => ({
        type: e.type as EmailTypes,
        address: e.value,
      })),
      phoneNumbers: formValues.phoneNumbers.map((e) => ({
        type: e.type as PhoneNumberTypes,
        number: e.value,
      })),
    };
  },

  formatKYCGeneralFormValuesAPIPayload: (
    formValues: ApplicantInformationFormValues,
  ): SubmitKYCGeneralTabData => {
    return {
      firstName: formValues.firstName.trim(),
      middleName: formValues.middleName.trim(),
      lastName: formValues.lastName.trim(),
      dateOfBirth: formValues.dateOfBirth
        ? DateHelpers.formatToYYYYMMDD(formValues.dateOfBirth)
        : null,
      countryOfBirth: formValues.countryOfBirth,
      gender: formValues.gender,
      emails: formValues.emails.map((e) => ({
        type: e.type as EmailTypes,
        address: e.value,
        isPrimary: e.primary.status,
      })),
      phoneNumbers: formValues.phoneNumbers.map((e) => ({
        type: e.type as PhoneNumberTypes,
        number: e.value,
        isPrimary: e.primary.status,
      })),
      socialMedia: getSocialMedia(formValues.socialMedia),
    };
  },

  formatKYCIdentificationFormValuesAPIPayload: (
    formValues: IdentificationFormValues,
  ): SubmitKYCIdentificationTabData => {
    return {
      isPEP: formatBooleanValue(formValues.isPEP),
      pepInformation: formValues.pepInformation.trim(),
      isRegulated: formatBooleanValue(formValues.isRegulated),
      regulationCountry: formValues.isRegulated
        ? formValues.regulationCountry
        : [],
      addresses: formValues.addresses.map((a) => ({
        _id: a._id,
        isPrimary: a.isPrimary,
        type: a.type,
        country: a.country,
        city: a.city,
        street: a.street,
        state: a.state,
        postalCode: a.postalCode,
        documentIds: a.documents?.map((d) => d.id),
      })) as RequestBodyAddressItemModel[],
      passports: formValues.passports.map(
        ({ issuedAt, expirationDate, document, ...rest }) => ({
          ...rest,
          issuedAt: issuedAt ? DateHelpers.formatToYYYYMMDD(issuedAt) : '',
          expirationDate: expirationDate
            ? DateHelpers.formatToYYYYMMDD(expirationDate)
            : '',
          documentIds: document.map((e) => e.id),
        }),
      ),
      nationality: formValues.nationality,
    };
  },

  formatKYCSOWFormValuesAPIPayload: (
    formValues: SOWFormValues,
  ): SubmitKYCSOWTabData => {
    return {
      sourceOfWealthCategories: formValues.sourceOfWealthCategories,
      sourceOfWealthDescription: formValues.sourceOfWealthDescription.trim(),
      employmentInformation: formValues.employmentHistory.length
        ? {
            isSelfEmployed: formatBooleanValue(
              formValues.employmentHistory[0].isSelfEmployed,
            ),
            employerName:
              (!formValues.employmentHistory[0].isBusinessUnderOwnName &&
                formValues.employmentHistory[0].isSelfEmployed) ||
              !formValues.employmentHistory[0].isSelfEmployed
                ? formValues.employmentHistory[0].employerName.trim()
                : '',
            occupation:
              (!formValues.employmentHistory[0].isBusinessUnderOwnName &&
                formValues.employmentHistory[0].isSelfEmployed) ||
              !formValues.employmentHistory[0].isSelfEmployed
                ? formValues.employmentHistory[0].occupation.trim()
                : '',
            natureOfBusiness: formValues.employmentHistory[0].isSelfEmployed
              ? formValues.employmentHistory[0].natureOfBusiness.trim()
              : '',
            isBussinessUnderOwnName: !formValues.employmentHistory[0]
              .isSelfEmployed
              ? null
              : formatBooleanValue(
                  formValues.employmentHistory[0].isBusinessUnderOwnName,
                ),
          }
        : null,
    };
  },

  formatVerificationDocumentsToFormModel: (
    documents: FetchVerificationDocumentsResponse,
    documentsAssociation: IDocumentAssociationInfo,
  ): ApplicationDocumentationStepFormValuesModel => {
    const {
      client,
      admin,
      clientChangeRequest,
      relationship_keyRelationshipTemplate,
    } = documents;

    const additionalDocuments = client.data.map((e) => {
      const { _id, field, answer } = e;
      return {
        id: _id,
        name: field.name,
        description: field.description,
        documents: AdditionalFieldHelpers.formatFromJSON(
          field.type,
          answer?.valueJSON || '',
        ),
      };
    });

    const answers =
      clientChangeRequest?.data ||
      relationship_keyRelationshipTemplate?.data ||
      [];

    const additionalFields = [...answers, ...admin.data].map((e) => {
      const { field, answer, review } = e;

      const isNotProvidedReason = Boolean(answer && !answer.isProvided);
      const value =
        !isNotProvidedReason && answer
          ? AdditionalFieldHelpers.formatFromJSON(field.type, answer.valueJSON)
          : undefined;
      const notProvidedReason = answer?.reason || '';

      return {
        id: e._id,
        value,
        review,
        name: field.name,
        description: field.description,
        isNotProvidedReason,
        notProvidedReason,
        type: field.type,
        relatedTo: field.relatedTo,
        options: {
          ...field.options,
          selectOptions: field.options.selectOptions.length
            ? field.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
            : [],
          formDocument: field.options.formDocument,
        },
      };
    });

    return {
      submitActionType: null,
      onboardingEntryType: OnboardingEntryTypes.Contact,
      additionalFields,
      additionalDocuments,
      documentsAssociation,
    };
  },

  submitVerificationStep: (
    values: ApplicationDocumentationStepFormValuesModel,
    initialFormValues: ApplicationDocumentationStepFormValuesModel,
    itemId: string,
    itemType: OnboardingEntryTypes,
    clientChangeRequestId: string,
  ): SubmitVerificationTabData => {
    return {
      itemId,
      itemType,
      operations:
        onboardingAPIAdapter.formatApplicationDocumentationAnswersToQueryOperations(
          values,
          initialFormValues,
        ),
      clientChangeRequestId,
    };
  },
};

export { personalDetailsAPIAdapter };
